<template>
  <v-container>
    <page-title title="Schedule a Scan"></page-title>
    <v-form v-model="valid">
      <v-row>
        <v-col
            cols="12"
            sm="3"
        >
          <v-text-field
              label="Days"
              filled
              dense
              v-model="days"
              :rules="[rules.required, rules.numeric]"


          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="3"
        >
          <v-text-field
              label="Hours"
              filled
              dense
              v-model="hours"
              :rules="[rules.required, rules.numeric]"


          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="3"
        >
          <v-text-field
              label="Minutes"
              filled
              dense
              v-model="minutes"
              :rules="[rules.required, rules.numeric]"

          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="3"
        >
          <v-text-field
              label="Seconds"
              filled
              dense
              v-model="seconds"
              :rules="[rules.required, rules.numeric]"

          ></v-text-field>
        </v-col>
        <v-col cols="12" >
          <v-select
              filled
              dense
              :rules="[rules.required]"
              :items="scan_options"
              label="Select Scan type"
              v-model="selected_scan_option"
              item-text="name"
              item-value="value"

          ></v-select>
        </v-col>

        <v-col cols="12" class="text-center" v-if="valid">
          Scan will run every {{ currentInterval }}.
        </v-col>
        <v-col
            cols="12"
            class="d-flex justify-center"
        >
          <v-btn
              elevation="2"
              outlined
              large
              :disabled="!valid || button_loading"
              :loading="button_loading"
              @click="scheduleScan"
          >Schedule
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

<!--    <page-title title="Scheduled Scans" class="mt-12"/>-->

<!--    <v-simple-table v-if="!table_loading">-->
<!--      <template v-slot:default>-->
<!--        <thead>-->
<!--        <tr>-->
<!--          <th class="text-center">-->
<!--            Server-->
<!--          </th>-->
<!--          <th class="text-center" id="scan-type-column">-->
<!--            Scan Type-->
<!--          </th>-->
<!--          <th class="text-center">-->
<!--            Interval-->
<!--          </th>-->
<!--        </tr>-->
<!--        </thead>-->
<!--        <tbody>-->
<!--        <tr-->
<!--            v-for="item in scheduled_scans"-->
<!--            :key="item.host"-->
<!--        >-->
<!--          <td class="text-center">{{ item.UUID }}</td>-->
<!--          <td class="text-center">{{ item.type }} Scan</td>-->
<!--          <td class="text-center">Runs Every {{ item.interval }}</td>-->
<!--        </tr>-->
<!--        </tbody>-->
<!--      </template>-->
<!--    </v-simple-table>-->
<!--    <v-skeleton-loader-->
<!--        class="mx-auto"-->
<!--        type="table-heading,table-tbody"-->
<!--        v-else-->
<!--    ></v-skeleton-loader>-->
  </v-container>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import axios from "axios";

export default {
  name: "ScanSchedule",
  components: {PageTitle},
  data() {
    return {
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
      valid: true,
      selected_scan_option: null,
      selected_server: null,
      scan_options: [
        {name: 'Scan installed software in registered devices', value: 1},
        {name: 'Scan for new hardware / devices added to the network', value: 2},
        {name: 'Malware scan', value: 3}
      ],
      server_options: [],

      button_loading: false,

      loader: null,

      scheduled_scans: [],

      table_loading: false,

      rules: {
        required: value => !!value || 'This field is required',
        numeric(value) {
          if (isNaN(value)) {
            return 'This must be a number.'
          } else if (+value < 0) {
            return 'Cannot be negative'
          } else {
            return true;
          }
        }
      },


    }

  },
  watch: {
    button_loading() {
      if (this.button_loading === true) {
        setTimeout(() => {
          this.button_loading = false;
          this.$store.commit('openAlert', {message: 'Scan scheduled successfully', type: 'success'})
        }, 2000);
      }
    }
  },
  methods: {
    secondsToString(s) {
      const d = Math.floor(s / (3600 * 24));
      s -= d * 3600 * 24;
      const h = Math.floor(s / 3600);
      s -= h * 3600;
      const m = Math.floor(s / 60);
      s -= m * 60;
      const tmp = [];
      (d) && tmp.push(d + ' days');
      (d || h) && tmp.push(h + ' hours');
      (d || h || m) && tmp.push(m + ' minutes');
      tmp.push(' and')
      tmp.push(s + ' seconds');
      return tmp.join(' ');
    },

    async getScheduledScansList() {
      try {
        this.table_loading = true;
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/scheduled`);

        this.scheduled_scans = resp.data.map(
            scheduled_scan => {
              const types = ['TCP/UDP Port Scan + ICMP', 'Only ICMP, no Port Scan', 'No ICMP, only TCP/UDP (slower)'];
              return {
                type: types[scheduled_scan.ScanType - 1],
                interval: this.secondsToString(scheduled_scan.Interval),
                host: this.server_options.find(server => server.UUID === scheduled_scan.UUID),
                UUID: scheduled_scan.UUID
              }
            }
        );
        this.table_loading = false;


      } catch (e) {
        this.table_loading = false;
        this.scheduled_scans = [];
        console.log(e)
        this.$store.commit('openAlert', {
          message: "Failed to fetch list of scheduled scans." + e.toString(),
          type: 'error'
        })
      }
    },

    async getServerList() {
      try {
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/server`);

        this.server_options = resp.data;


      } catch (e) {
        this.$store.commit('openAlert', {message: `Failed to fetch server list. Details: ${e.message}`, type: 'error'});

      }
    },

    async scheduleScan() {
      try {
        this.button_loading = true;


        // const resp = await axios.post(`${process.env.VUE_APP_API_URL}/schedule`, new URLSearchParams({
        //   UUID: this.selected_server,
        //   ScanType: this.selected_scan_option,
        //   Interval: this.totalSeconds
        // }));
        // console.log(resp)

        // const resp = await axios.post(, {
        //   ScanType:
        // });

        // await this.getScheduledScansList();


      } catch (e) {
        this.scheduled_scans = [];
        console.log(e)
        this.$store.commit('openAlert', {
          message: "Failed to Schedule Scan." + e.toString(),
          type: 'error'
        })
      }
    }
  },
  async mounted() {
  },

  computed: {
    totalSeconds() {
      return parseInt(this.days || 0) * 86400 + parseInt(this.hours || 0) * 3600 + parseInt(this.minutes || 0) * 60 + parseInt(this.seconds || 0);
    },
    currentInterval() {
      return this.secondsToString(this.totalSeconds);
    }
  }
}
</script>

<style scoped>

</style>