<template>
  <v-system-bar app window>
    <looping-rhombuses-spinner
        :animation-duration="5000"
        :size="8"
        color="white"
        class="mr-2"
        v-if="!scan_active"
    />
    <trinity-rings-spinner
        :animation-duration="1500"
        :size="30"
        color="white"
        v-else
    />
    <div v-if="scan_active" class="ml-2">Scan in Progress</div>
    <div v-else>Last scan conducted at Friday, 4:30 PM</div>
    <v-spacer></v-spacer>

   <span class="d-sm-block d-none"> Ground Zero Server Status : &nbsp; <strong class="green--text">ONLINE</strong></span>
    <v-spacer></v-spacer>
    {{ hours }}:{{ minutes }}:{{ seconds }} {{ am_pm_indicator }}

  </v-system-bar>
</template>

<script>
const SECOND = 1000;
const HOUR = 12;

function getHourTime(h) {
  return h >= 12 ? 'PM' : 'AM'
}

function getZeroPad(n) {
  return (parseInt(n, 10) >= 10 ? '' : '0') + n
}

import {LoopingRhombusesSpinner} from 'epic-spinners'

import {TrinityRingsSpinner} from 'epic-spinners';


export default {
  name: "SystemBar",
  data() {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
      am_pm_indicator: '',
    };
  },
  mounted() {
    const timer = window.setTimeout(this.updateDateTime, SECOND);
    this.$on('hook:destroyed', () => window.clearTimeout(timer))
  },
  methods: {
    updateDateTime() {
      const now = new Date();
      this.hours = now.getHours();
      this.minutes = getZeroPad(now.getMinutes());
      this.seconds = getZeroPad(now.getSeconds());
      this.am_pm_indicator = getHourTime(this.hours);
      this.hours = this.hours % HOUR || HOUR;
      this.$options.timer = window.setTimeout(this.updateDateTime, SECOND);
    },
  },
  computed: {
    scan_active() {
      return this.$store.state.scanning;
    }
  },
  components: {
    LoopingRhombusesSpinner,
    TrinityRingsSpinner
  }
}
</script>

<style scoped>

</style>