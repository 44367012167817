<template>
  <v-app id="inspire">
    <SystemBar/>

    <NavDrawer @clickTab="updateModel"/>
    <v-main class="ma-4">
      <v-container v-if="alert">
        <v-row class="d-flex justify-center">
          <v-col cols="12" md="6">
            <v-slide-y-transition>
              <v-alert
                  border="right"
                  colored-border
                  :type="alert_type"
                  elevation="2"
                  v-model="alert"
                  dismissible
                  close-icon="mdi-close"
                  class="pr-8"
              >
                {{ alert_message }}
              </v-alert>
            </v-slide-y-transition>
          </v-col>
        </v-row>
      </v-container>
      <router-view></router-view>

    </v-main>

  </v-app>
</template>

<script>
import SystemBar from "@/components/SystemBar";
import NavDrawer from "@/components/NavDrawer";

export default {
  components: {NavDrawer, SystemBar},
  data() {
    return {
      model: 0,
      dialog: false,

    }
  },
  methods: {
    updateModel: function (num) {
      this.model = num
    }
  },
  computed: {
    alert: {
      get() {
        return this.$store.state.alert;
      },
      set(newValue) {
        this.$store.commit('setAlert', {val: newValue})
      }
    },

    alert_message() {
      return this.$store.state.alert_message;
    },

    alert_type() {
      return this.$store.state.alert_type;
    }

  }
};
</script>


<style>
:root /deep/ .v-application .elevation-3 {
  box-shadow: 0px 3px 3px 1px rgba(230, 5, 5, 1),
  0px 3px 5px 0px rgba(0, 0, 0, 0), 0px 1px 11px 1px rgba(0, 0, 0, 0.1) !important;
}
</style>