<template>
  <v-container>
    <h3 class="text-center text-h4 text--darken-3">{{ title }}</h3>
    <v-row class="d-flex justify-center mt-1 mb-6">
      <v-col cols="6">
        <hr class="custom-divider">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PageTitle",
  props: ["title"]
}
</script>

<style scoped>

hr.custom-divider {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0));
}
</style>