<template>
  <v-card class="mx-auto custom-card" flat outlined>
    <v-card-text>

      <v-container>
        <v-row>
          <v-col cols="3" class="d-flex justify-center text-center" v-if="card_type">
            <v-img size="4em" src="https://i.imgur.com/ee1shf4.png" style="cursor: pointer"
                   onclick="window.open('https://i.imgur.com/ee1shf4.png')"></v-img>
          </v-col>
          <v-col cols="9">
            <p class="text--primary text--black machine-hostname"> Directive: {{ directive }} </p>
            <p>
              <v-icon class="mr-1" color="grey" size="18"> mdi-clock</v-icon>
              Response time : Yesterday, 11:43 PM
              <br class="mb-1">
              <v-icon class="mr-1" color="grey" size="18"> mdi-account</v-icon>
              Username: anchit
              <br class="mb-1">

              <v-icon color="grey" class="mr-1" size="18"> mdi-key</v-icon>
              Unique Identifier: <code>c8f46276-7713-4ca0-a89e-5f8af704ab98</code>
            </p>
          </v-col>
          <v-col cols="12" v-if="!card_type">
            <pre style="font-family: monospace">
  {
    "Identifier": "6b61eb6c8e496ec7854de1767913eab9d75c8a9ff03435a30d776d9ee67d076a",
    "gid": "1000",
    "homedir": "/home/icewreck",
    "name": "Anchit",
    "uid": "1000",
    "username": "icewreck"
  }

              </pre>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

  </v-card>
</template>

<script>
export default {
  name: "ResponseCard",
  props: ['card_type', 'directive'],
  data() {
    return {
      reveal: false,

    };
  },

};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.machine-hostname {
  font-family: "Space Mono", Roboto, serif;
  font-size: 1.3em;
  font-weight: bold;
}

.custom-card {
  border-left: red 3px solid;
}
</style>