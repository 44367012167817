import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import moment from 'moment'

Vue.use(Vuetify);
Vue.prototype.moment = moment

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: '#d32f2f',
                secondary: '#d32f2f',
                accent: '#ff9000',
                error: '#FF5252',
                info: '#dadada',
                success: '#4CAF50',
                warning: '#FFC107',
            }
        }
    }
});
