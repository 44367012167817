import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,

});

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        scanning: false,
        alert: false,
        alert_type: 'success',
        alert_message: ''
    },
    getters: {},
    mutations: {
        scanStarted(store) {
            store.scanning = true
        },
        stopScan(store) {
            store.scanning = false;
        },

        openAlert(store, {message, type}) {
            store.alert = true;
            store.alert_message = message;
            store.alert_type = type;
        },


        setAlert(store, {val}) {
            store.alert = val;
        }
    },
    actions: {
        async initiateScan({commit}, {server_id, scan_type}) {
            console.log("Starting scan on server ", server_id, "with scan type", scan_type);
            try {
                const resp = await axios.post(`${process.env.VUE_APP_API_URL}/scan`, new URLSearchParams(
                    {
                        ScanType: scan_type,
                        UUID: server_id
                    }
                ));
                console.log(resp);
                commit('openAlert', {
                    message: `Scan started successfully.`,
                    type: "success"
                })
                commit('scanStarted');

                return true;
            } catch (e) {
                console.log(e);
                commit('openAlert', {
                    message: `Failed to start scan. Error : ${e.message}`, type: 'error'
                })
                commit('stopScan');
                return false;
            }
        }
    },
    modules: {},
    plugins: [
        vuexLocal.plugin
    ]
});

