<template>
  <v-navigation-drawer v-model="drawer" permanent app :mini-variant="mini" mini-variant-width="70">
    <v-sheet class="pa-4" @click="!mini ? mini = true: mini=false">

      <div class="text-h5 font-weight-black" v-if="!mini">CorpoTecht</div>
      <v-btn fab small class="text-h7 font-weight-black" v-if="mini">T</v-btn>

      <div class="text-sm-body-2" v-if="!mini">Central Admin Panel</div>

    </v-sheet>
    <v-divider></v-divider>

    <v-list flat>
      <v-list-item-group color="primary">
        <v-list-item v-for="item in links" :key="item.icon" router :to="item.route">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

    </v-list>
    <template v-slot:append>
      <div class="pa-2 text-center">

        <v-btn icon text @click="mini=!mini" v-if="mini">


          <v-icon>mdi-chevron-right</v-icon>

        </v-btn>
        <v-btn block text v-if="!mini" @click="mini=true">
          <v-icon>mdi-chevron-left</v-icon>
          Collapse
        </v-btn>
      </div>
    </template>


  </v-navigation-drawer>

</template>

<script>
export default {
  name: "NavDrawer",
  data() {
    return {
      drawer: null,
      links: [
        {icon: 'mdi-home', title: "Home", route: '/'},
        {icon: "mdi-desktop-classic", title: "Client PCs", route: '/computers/list'},

        {icon: "mdi-calendar-clock-outline", title: "Schedule Scans", route: '/schedule'},

        {icon: "mdi-cog", title: "Settings", route: '/settings'},

        ["mdi-cog", "Settings"],
      ],
      mini: false
    }
  },
}
</script>

<style scoped>

</style>