<template>
  <v-container class="ma-0 pa-0 fill-height" style="max-width: 100%">
    <iframe
        :src="iframe_url"
        frameborder="0"

        ref="frame"
        @load="loaded"
        v-show="!loading"
        height="100%" width="100%"></iframe>
    <v-container style="height: 400px;" v-if="loading">
      <v-row
          class="fill-height"
          align-content="center"
          justify="center"
      >
        <v-col
            class="text-subtitle-1 text-center"
            cols="12"
        >
          Loading dashboard...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
              color="red accent-4"
              indeterminate
              rounded
              height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </v-container>

</template>

<script>
export default {
  name: "Kibana",
  data() {
    return {
      loading: true,
    }
  },

  methods: {
    loaded() {
      this.loading = false;
    }
  },
  computed: {
    iframe_url() {
      return `${process.env.VUE_APP_DASHBOARD_IFRAME_URL}`
    }
  }
}
</script>

<style scoped>

</style>