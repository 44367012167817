import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "@/views/HomePage";
import ServerListView from "@/views/ServerListView";
import ServerStatus from "@/views/ServerStatus";
import ScanSchedule from "@/views/ScanSchedule";
import AgentScans from "@/views/AgentScans";
import AgentLessScans from "@/views/AgentLessScans";
import Kibana from "@/views/Kibana";
import ClientPCDetailView from "@/views/ClientPCDetailView";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage
    },
    {
        path: '/computers/list',
        name: 'Server List',
        component: ServerListView
    },
    {
        path: '/scan/results/agent',
        name : 'Agent Scan Results',
        component: AgentScans
    },
    {
        path: '/scan/results/agentless',
        name: 'Agentless Scan Results',
        component: AgentLessScans
    },
    {
        path: '/connectivity-check',
        name: 'Connectivity Check',
        component: ServerStatus
    },
    {
        path: '/schedule',
        name: 'Schedule Scans',
        component: ScanSchedule
    },
    {
        path: '/kibana',
        name: 'Kibana Dashboard',
        component: Kibana
    },
    {
        path: '/computer/:computer_id/details',
        name: 'computer-detail',
        component: ClientPCDetailView,
        props: true,
    }

]

const router = new VueRouter({
    mode: process.env.IS_ELECTRON ? 'hash' : 'history',
    base: process.env.BASE_URL,
    routes
})

export default router;