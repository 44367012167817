<template>
  <v-card class="mx-auto custom-card" flat outlined>
    <v-card-text>

      <v-container>
        <v-row>
          <v-col cols="3" class="d-flex justify-center text-center">
            <v-icon size="4em">mdi-console-network</v-icon>
          </v-col>
          <v-col cols="9">
            <p class="text--primary text--black machine-hostname"> {{ card.name }} </p>
            <p>
              <v-icon class="mr-1" color="grey" size="18"> mdi-console</v-icon>
              Operating System : Windows 11 Pro (21H2)
              <br>
              <v-icon color="grey" size="18"> mdi-memory</v-icon>
              Architecture: amd64
              <br>
              <v-icon color="grey" size="18"> mdi-memory</v-icon>
              Last seen: {{ getLastSeen(card.last_seen) }}
              <br>
              <v-icon color="grey" size="18"> mdi-key</v-icon>
              Unique ID: <br><code>{{ card.unique_id }}</code>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn text outlined color="red " class="red--text lighten-3" @click="openPCDetailPage">
        Open details
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ServerCard",
  props: ['card', 'index'],
  data() {
    return {
      reveal: false,

    };
  },
  methods: {
    openPCDetailPage() {
      this.$router.push({name: 'computer-detail', params: {computer_id: this.card.unique_id, card: this.card}})
    },

    getLastSeen(date) {
      return new Date(Date.parse(date)).toUTCString()
    }
  }
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.machine-hostname {
  font-family: "Space Mono", Roboto, serif;
  font-size: 1.3em;
  font-weight: bold;
}

.custom-card {
  border-left: red 3px solid;
}
</style>