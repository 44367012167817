<template>
  <v-container class="fill-height">
    <v-row class="text-center">
      <v-col cols="12" class="d-flex justify-center">
        <!--        <v-icon size="10em">mdi-nas</v-icon>-->
        <flower-spinner
            :animation-duration="6000"
            align="center"
            :size="100"
            color="red"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to CorpoTecht
        </h1>

        <p class="subheading font-weight-regular">
          A one stop solution to monitor and control devices on your organization network
        </p>
      </v-col>


      <v-col
          class="mb-5"
          cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          v0.1.0
        </h2>


      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { FlowerSpinner } from 'epic-spinners'
export default {
  name: "HomePage",
  components: {
    FlowerSpinner
  }
}
</script>

<style scoped>

</style>