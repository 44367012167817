<template>
  <v-container>
    <page-title title="Agent Scan Results"/>
    <v-data-iterator
        :items="items"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="search"
        :sort-by="sortBy.toLowerCase()"
        :sort-desc="sortDesc"
        hide-default-footer
        v-if="!loading && !error"
    >
      <template v-slot:header>
        <v-toolbar

            class="mb-7"
            flat
            color="transparent"
        >
          <v-text-field
              v-model="search"
              clearable
              flat
              solo-inverted
              hide-details
              prepend-inner-icon="mdi-magnify"
              dark
              label="Search"
          ></v-text-field>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer></v-spacer>
            <v-select
                v-model="sortBy"
                flat
                solo-inverted
                hide-details
                :items="keys"
                prepend-inner-icon="mdi-magnify"
                label="Sort by"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn-toggle
                v-model="sortDesc"
                mandatory
            >
              <v-btn
                  large
                  depressed
                  :value="false"

              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn
                  large
                  depressed
                  :value="true"
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col
              v-for="(item, index) in props.items"
              :key="item.MAC"
              cols="12"
              md="6"


          >
            <v-card>
              <v-card-title class="subheading font-weight-bold">
                {{ item.HostName || '[NO HOSTNAME]' }}
                <v-spacer></v-spacer>
                <v-btn @click="selected_item=index; networkDialog=true;" class="ma-2">
                  <v-icon left>mdi-expansion-card</v-icon>
                  Interfaces
                </v-btn>
                <v-btn @click="selected_item=index; portDialog=true;" class="ma-2">
                  <v-icon left>mdi-ethernet</v-icon>
                  Ports
                </v-btn>
              </v-card-title>

              <v-divider></v-divider>

              <v-list dense>
                <v-list-item
                    v-for="(key, index) in filteredKeys"
                    :key="index"
                >
                  <v-list-item-content :class="{ 'red--text': sortBy === key }">
                    {{ key }}:
                  </v-list-item-content>
                  <v-list-item-content
                      class="align-end"
                      :class="{ 'red--text': sortBy === key }"
                  >
                    {{ item[key] }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row
            class="mt-7 mb-16 ml-4 mr-4"
            align="center"
            justify="center"
        >
          <span class="grey--text">Items per page</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  dark
                  text
                  color="red"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span
              class="mr-4
            grey--text"
          >
            Page {{ page }} of {{ numberOfPages }}
          </span>
          <v-btn
              fab
              dark
              color="red darken-3"
              class="mr-1"
              @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
              fab
              dark
              color="red darken-3"
              class="ml-1"
              @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>

    <v-container class="fill-height d-flex justify-center" v-else-if="!loading && error">
      <v-row class="d-flex justify-center">
        <v-col cols="12" class="d-flex justify-center">

          <v-icon size="100" color="white">mdi-alert</v-icon>


        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn

              text
              outlined
              @click="getResults"
          >Retry
          </v-btn>

        </v-col>
      </v-row>
    </v-container>
    <v-container style="height: 400px;" v-else>
      <v-row
          class="fill-height"
          align-content="center"
          justify="center"
      >
        <v-col
            class="text-subtitle-1 text-center"
            cols="12"
        >
          Fetching latest results...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
              color="red"
              indeterminate
              striped
              rounded
              height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="portDialog"
        overlay-opacity="0.3"
        max-width="30em"
    >
      <v-card>
        <v-card-title class="text-h5">
          Port Scan Report
        </v-card-title>
        <v-divider class="mb-3"></v-divider>

        <v-card-text>
          <v-simple-table
              fixed-header
              height="300px"
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Port
                </th>
                <th class="text-left">
                  Service
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in items[selected_item].Ports"
                  :key="item.name"
              >
                <td>
                  <v-chip small>{{ item.Port }}</v-chip>
                </td>
                <td>{{ item.Service }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              text
              outlined
              @click="portDialog = false"
          >
            Dismiss
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="networkDialog"
        overlay-opacity="0.3"
        width="80%"
    >
      <v-card>
        <v-card-title class="text-h5">
          Network Interfaces
        </v-card-title>
        <v-divider class="mb-3"></v-divider>

        <v-card-text>
          <v-simple-table
              fixed-header
              height="50%"
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  MAC
                </th>
                <th class="text-left">
                  Manufacturer
                </th>
                <th class="text-left">
                  IP
                </th>
                <th class="text-left">
                  Network
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in items[selected_item].NetworkInterfaces"
                  :key="item.name"
              >

                <td>{{ item.Name }}</td>
                <td><code>{{ item.MAC }}</code></td>
                <td>{{ item.Manufacturer }}</td>
                <td><code>{{ item.IP }}</code></td>
                <td><code>{{ item.Network }}</code></td>

              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              text
              outlined
              @click="networkDialog = false"
          >
            Dismiss
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import axios from "axios";

export default {
  name: "AgentScans",
  components: {PageTitle},
  data() {
    return {
      loading: false,
      error: false,
      portDialog: false,
      networkDialog: false,
      selected_item: null,

      itemsPerPageArray: [2, 4, 6, 8],
      search: '',
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 2,
      sortBy: '',
      keys: [
        'HostName',
        'OSVersion',
        'OSType',
        'Arch',
        'ExternalIP',
        'Domain',
        'Workgroup',
        'OutboundIP',
      ],
      items: []
    }
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    filteredKeys() {
      return this.keys.filter(key => key !== 'Name')
    },
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },

    async getResults() {
      try {
        this.loading = true;
        const resp = await axios.get(`${process.env.VUE_APP_ELASTICSEARCH_URL}/agent/_search?size=10000`);
        this.items = resp.data.hits.hits.map(e => e._source);

        // TODO: Remove artificial delay
        await (async () => await new Promise(resolve => setTimeout(resolve, 1000)))();

        this.loading = false;
      } catch (e) {
        console.log(e);
        this.$store.commit('openAlert', {
          message: `Failed to fetch scan results. Details: ${e.message}`,
          type: 'error'
        });
        this.error = true;
        this.loading = false;
      }


    }
  },
  async mounted() {
    await this.getResults();
  }
}
</script>

<style scoped>

</style>