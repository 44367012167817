<template>
  <v-container v-if="!error">
    <page-title title="Server Status"/>
    <v-simple-table v-if="!loading">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="">Status</th>
          <th class="text-left">
            Hostname
          </th>
          <th class="text-left">
            SSH Port
          </th>

          <th class="text-center">
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in servers"
            :key="item.UUID"
        >
          <td>
                        <v-chip
                            small

                            color="green darken-2"
                            class="font-weight-bold"
                        >UP
                        </v-chip>
          </td>
          <td><code>{{ item.Host }}</code></td>
          <td>{{ item.SSHPort }}</td>
          <td CLASS="justify-end">
            <looping-rhombuses-spinner
                :animation-duration="4000"
                :size="20"
                color="green"
                class="d-block align-end"
            />

          </td>

        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-skeleton-loader
        class="mx-auto"
        type="table-heading,table-tbody"
        v-else
    ></v-skeleton-loader>
  </v-container>
  <v-container class="fill-height" v-else>
    <v-row class="d-flex justify-center">
      <v-col cols="12" class="d-flex justify-center">

        <v-icon size="100" color="white">mdi-alert</v-icon>


      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn

            text
            outlined
            @click="getServerList"
        >Retry
        </v-btn>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import {LoopingRhombusesSpinner} from 'epic-spinners';
// import axios from "axios";

export default {
  name: 'ServerStatus',
  components: {PageTitle, LoopingRhombusesSpinner},
  data() {
    return {
      servers: [],

      error: false,

      loading: true
    }
  },

  methods: {
    async getServerList() {
      this.error = false;
      this.loading = true;
      try {
        // const resp = await axios.get(`${process.env.VUE_APP_API_URL}/server`);
        //
        // this.servers = resp.data;
        await (async () => await new Promise(resolve => setTimeout(resolve, 1000)))();
        this.servers = [
          {}
        ];
        this.loading = false;

      } catch (e) {
        console.log(e)
        this.$store.commit('openAlert', {message: `Failed to fetch server list. Details: ${e.message}`, type: 'error'});
        this.error = true;
        this.loading = false;
      }
    },
  },

  async mounted() {
    await this.getServerList();
  }
}
</script>

<style lang="scss" scoped>


</style>
