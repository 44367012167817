<template>
  <v-container class="py-8 px-6" fluid v-if="!error">
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          max-width="400px"
      >
        <v-card v-if="scannerActive" dark class="pa-4">
          <v-card-title class="justify-center">
            <div>
              <h3 class="headline">Scan in progress...</h3>
            </div>
          </v-card-title>

          <v-card-actions class="justify-center mt-4 mb-6">

            <fulfilling-bouncing-circle-spinner
                :animation-duration="4000"
                :size="60"
                color="red"
            />
            <br>

          </v-card-actions>
          <v-container class="d-flex justify-center ">
            <!--            <v-spacer></v-spacer>-->
            <v-btn
                color="grey"
                text
                @click=" dialog=false;"

                class="mr-1"

            >
              Minimize
            </v-btn>
            <v-btn
                color="red"
                text
                @click=" stopScan"
                outlined


            >
              Stop
            </v-btn>


          </v-container>
        </v-card>
        <v-card v-if="!scannerActive" class="pa-1">
          <v-card-title>
            <span class="text-h5">Scan options</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="mt-2">
                <v-col
                    class="d-flex"
                    cols="12"

                >
                  <v-text-field :value="scanning_server_id" label="server_id" rounded outlined dense
                                disabled></v-text-field>
                </v-col>
                <v-col
                    class="d-flex"
                    cols="12"

                >

                  <v-select
                      outlined
                      :items="scan_options"
                      label="Select Scan type"
                      v-model="selected_scan_option"
                      item-value="value"
                      item-text="name"

                  ></v-select>
                </v-col>

              </v-row>
            </v-container>


          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
                color="red"
                text
                @click="startScan"
                :disabled="selected_scan_option===null"
            >
              Start Scanning
            </v-btn>


          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <template class="" v-if="!loading">
        <v-col v-for="(card, index) in cards" :key="card.UUID" lg="4" md="6" sm="12" class="pa-7">
          <ServerCard
              :card="card" :index="index"
              @editCard="editCard"
              @startScan="openScanDialog"></ServerCard>
          <!--          :host='card.Username + "@" + card.Host' :ssh_port="card.SSHPort" :password="card.Password"-->
          <!--          :ssh_path="card.SSHKeyPath" :uuid="card.UUID"-->

        </v-col>
      </template>


      <template v-else>
        <v-col lg="3" md="6" sm="12" v-for="i in 7" :key="i">
          <v-skeleton-loader

              type="article, actions"
              class="mx-4 my-1"
          ></v-skeleton-loader>
        </v-col>
      </template>
    </v-row>
    <v-dialog
        v-model="serverForm"
        max-width="600px"
        persistent
    >

      <v-card>
        <v-card-title>
          <span class="text-h5" v-if="!editing_server">Add a server</span>
          <span class="text-h5" v-else>Editing Server</span>

        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
              >
                <v-text-field
                    label="UUID"
                    v-model="c_uuid"
                    required
                    v-if="editing_server"

                    filled
                    flat
                    disabled
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-text-field
                    label="Username*"
                    v-model="c_username"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="4"
              >
                <v-text-field
                    label="SSH Port*"
                    v-model="c_ssh_port"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="8"
              >
                <v-text-field
                    label="Hostname*"
                    v-model="c_hostname"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-text-field
                    label="SSH Key Path "
                    hint="leave blank for default"
                    v-model="c_ssh_key_path"
                    required

                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-text-field
                    label="Password "
                    hint="Leave blank if not needed"
                    v-model="c_password"
                    required
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              color="red"
              @click="closeServerForm"
          >
            Close
          </v-btn>
          <v-btn
              color="red"
              text
              @click="createOrUpdateServer"
              :loading="c_button_loading"
              :disabled="c_button_loading"
          >
            <template v-if="editing_server">Update Server</template>
            <template v-else>Create Server</template>
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
  <v-container class="fill-height" v-else>
    <v-row class="d-flex justify-center">
      <v-col cols="12" class="d-flex justify-center">

        <v-icon size="100" color="white">mdi-alert</v-icon>


      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn

            text
            outlined
            @click="getServerList"
        >Retry
        </v-btn>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServerCard from "@/components/ServerCard";
import {FulfillingBouncingCircleSpinner} from 'epic-spinners'
import axios from "axios";

export default {
  name: "ServerListView",
  components: {ServerCard, FulfillingBouncingCircleSpinner},


  data() {
    return {
      cards: [],
      dialog: false,

      scanDialog: false,
      scanForPorts: false,
      scanUsingICMP: false,

      scan_options: [
        {name: 'TCP/UDP Port Scan + ICMP for discovery', value: 1},
        {name: 'Only ICMP for discovery, no Port Scan', value: 2},
        {name: 'No ICMP for discovery, only TCP/UDP (slower)', value: 3}
      ],
      selected_scan_option: null,
      serverForm: false,

      error: true,

      loading: true,


      editing_server: false,
      c_uuid: null,
      c_username: null,
      c_hostname: null,
      c_password: null,
      c_ssh_key_path: null,
      c_ssh_port: null,

      c_button_loading: false,

      scanning_server_id: null,
    }
  },

  methods: {
    openScanDialog(index) {
      console.log(index);
      this.scanning_server_id = this.cards[index].UUID;
      this.dialog = true;

    },
    async startScan() {
      const status = await this.$store.dispatch('initiateScan', {
        server_id: this.scanning_server_id,
        scan_type: this.selected_scan_option
      });
      if (!status) {
        this.dialog = false;
      }
    },

    stopScan() {
      this.dialog = false;
      this.$store.commit('stopScan');
      this.$store.commit('openAlert', {message: 'Current scan stopped successfully.', type: 'success'});
    },

    async getServerList() {
      this.error = false;
      this.loading = true;
      try {
        const resp = await axios.get(`https://sih2022.abifog.com/api/devicelist`);
        
        this.cards = resp.data;
        
        // TODO : Remove this artificial delay
        await (async () => await new Promise(resolve => setTimeout(resolve, 1000)))();
        console.log(this.cards);
        this.loading = false;

      } catch (e) {
        console.log(e)
        this.$store.commit('openAlert', {message: `Failed to fetch server list. Details: ${e.message}`, type: 'error'});
        this.error = true;
        this.loading = false;
      }
    },

    editCard(index) {
      this.serverForm = true;

      this.editing_server = true;
      this.c_uuid = this.cards[index].UUID;
      this.c_username = this.cards[index].Username;
      this.c_hostname = this.cards[index].Host;
      this.c_password = this.cards[index].Password;
      this.c_ssh_key_path = this.cards[index].SSHKeyPath;
      this.c_ssh_port = this.cards[index].SSHPort;

    },

    closeServerForm() {
      this.editing_server = false;
      this.c_uuid = null;
      this.c_username = null;
      this.c_hostname = null;
      this.c_password = null;
      this.c_ssh_key_path = null;
      this.c_ssh_port = null;

      this.serverForm = false;
    },
    generateUUID(a) {
      return a ? (a ^ Math.random() * 16 >> a / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, this.generateUUID)
    },
    async createOrUpdateServer() {
      try {
        this.c_button_loading = true;
        let payload = {
          SSHPort: this.c_ssh_port || "",
          Username: this.c_username || "",
          Host: this.c_hostname || "",
          UUID: this.c_uuid || this.generateUUID(),
          Password: this.c_password || "",
          SSHKeyPath: this.c_ssh_key_path || "",
        };

        // TODO : Remove this artificial delay
        await (async () => await new Promise(resolve => setTimeout(resolve, 500)))();

        const resp = await axios.post(`${process.env.VUE_APP_API_URL}/update`, new URLSearchParams(payload));

        this.$store.commit('openAlert', {
          message: resp.data.status,
          type: 'success'
        });
        this.c_button_loading = false;
        this.closeServerForm();
        await this.getServerList();

      } catch (e) {
        this.c_button_loading = false;
        this.closeServerForm();
        console.log(e);

        if (e.response.status === 400) {
          this.$store.commit('openAlert', {
            message: `Failed to create / update server, Error : ${e.response.data.error}`,
            type: 'error'
          });
        } else {
          this.$store.commit('openAlert', {
            message: `Failed to create / update server, Error : ${e.message}`,
            type: 'error'
          });
        }

      }
    }
  },

  computed: {
    scannerActive() {
      return this.$store.state.scanning
    },
  },
  async mounted() {
    await this.getServerList();
  },

}
</script>

<style scoped>

</style>